import React from "react";
import Layout from "src/components/Layout";
import HeadSEO from "src/components/HeadSEO";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Heading, Text, VStack, Grid, Button } from "@chakra-ui/react";
import Container from "src/components/Container";
import FormsBanner from "src/components/banners/FormsBanner";
import RaffleForm from "../forms/Raffle Poster 2024 Final.pdf";
import StallForm from "../forms/E.V.F. Stalll EOI FORM 2023.pdf";
import EntertainmentForm from "../forms/E.V.F. ENTERTAINMENT APPLICATION 2022.pdf";
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";

const getData = graphql`
  {
    markdownRemark(fields: { slug: { eq: "/pages/forms/" } }) {
      frontmatter {
        banner_text
        before_form_grid
        show_entertainment_form
        show_race_form
        show_stallholder_form
      }
    }
  }
`;

const Contact = () => {
  const data = useStaticQuery(getData);
  const page = data.markdownRemark.frontmatter;
  const showStall = data.show_stallholder_form;
  const showRace = data.show_race_form;
  const showEntertainment = data.show_entertainment_form;
  return (
    <Layout>
      <HeadSEO
        title="Raffle | Evandale Village Fair"
        description="Raffle tickets on sale at Evandale Sunday Markets, Evandale Pharmacy weekdays and Evandale Information Centre."
        slug="/raffle/"
      />
      <Box as="section">
        <Container size="md" py="6rem">
          <VStack align="flex-start" spacing="2rem">
            <Heading as="h1" size="2xl">
              Raffle
            </Heading>
          <Text>Raffle tickets on sale at Evandale Sunday Markets, Evandale Pharmacy weekdays and Evandale Information Centre.</Text>
          <Button
              as="a"
              href={RaffleForm}
              colorScheme="red"
              bg="red.600"
              _hover={{ bg: "red.700" }}
              rounded="0"
            >
              View Raffle Flyer
            </Button>
          </VStack>
        </Container>
      </Box>
    </Layout>
  );
};

export default Contact;
